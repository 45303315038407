import axios from 'axios'

export default () => {
    return axios.create({
        baseURL: `${process.env.VUE_APP_URL}`,
        headers: {
            "Authorization": process.env.VUE_APP_AUTHTOKEN,
            "Content-Type": process.env.VUE_APP_CONTENT_TYPE
        }
    })
}