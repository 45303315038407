import AuthService from '../../services/AuthService'
import router from '../../router'

const state = () => ({
    isAuthenticated: false,
    loadingUser: false,
    redirect: '',
    token: localStorage.getItem('token') || null,
    user: JSON.parse(localStorage.getItem('user')) || null,
    currentStep: localStorage.getItem('token') ? 3 : 1 || 1,
    personalData: {
        name: '',
        email: '',
        phone: '',
        state: '',
        city: ''
    },
    professionalData: {
        profession: '',
        specialty: '',
        CRM: '',
        CRF: '',
        soubePor: '',
        authorize: '',
    },
    completed: false
})
  
const mutations = {
    REDIRECT: (state, payload) => {
        state.redirect = payload;
    },
    SET_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));
        state.isAuthenticated = true;
        state.user = payload.user
        state.token = payload.token
        state.currentStep = 3;
        const currentTime = new Date();
        const targetTime = new Date("2023-05-06T08:30:00");

        if (currentTime > targetTime) {
            router.push({path: `/aovivo`});
        } else {
            router.push({path: `/obrigado`});
        }
        state.redirect = null;
    },
    AUTH_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));
        state.currentStep = 3;
        state.isAuthenticated = true;
        state.user = payload;
    },
    SET_LOADING_USER: (state, payload) => {
        state.loadingUser = payload
    },
    LOG_USER_OUT(state){
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('municipios');
        localStorage.removeItem('notifications');
        state.currentStep = 1;
        state.token = null;
        state.isAuthenticated = false;
        state.user = null;
        if(router.currentRoute.value.path !== '/auth'){
            router.push({path: `/auth`});
        }
    },
    SETPERSONALDATA(state, personalData) {
        state.personalData = personalData
    },
    SETPROFESSIONALDATA(state, professionalData) {
        state.professionalData = professionalData
    },
    NEXTSTEP(state) {
        state.currentStep++
    },
    PREVIOUSSTEP(state) {
        state.currentStep--
    },
    SETCOMPLETED(state, completed) {
        state.completed = completed
    }
}

const actions = {
    async AuthUser({commit}){
        try {
            const response = await AuthService.auth();
            commit('AUTH_USER', response.data);
            return response.data;
        } catch (error) {
            commit('LOG_USER_OUT');
        }
    },
    async loginUser({commit}, credentials ){
        try {
            commit('SET_LOADING_USER', true);
            const response = await AuthService.login(credentials);
            commit('SET_USER', response.data);
            commit('SET_LOADING_USER', false);
        } catch (error) {
            commit('SET_LOADING_USER', false);
            setTimeout(() => {
                router.push({path: `/register`});
            }, 4000);
            commit('SET_ALERT', {
                heading: 'erro',
                message: error.response.data.message
            });
        }
    },
    async registerUser({commit}, credentials){
        try{
            commit('SET_LOADING_USER', true);
            const response = await AuthService.create(credentials);
            commit('SET_USER', response.data);
            commit('SET_LOADING_USER', false);
            return true;
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'erro',
                message: error.response.data.message
            });
            return false;
        }
    },
    async fetchUser({commit}){
        try{
            commit('SET_LOADING_USER', true)
            const response = await AuthService.getUser();
            commit('AUTH_USER', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'erro',
                message: error.response.data.message
            });
        }
    },
    savePersonalData({ commit }, personalData) {
        commit('SETPERSONALDATA', personalData)
    },
    saveProfessionalData({ commit }, professionalData) {
        commit('SETPROFESSIONALDATA', professionalData)
    },
    nextStep({ commit }) {
        commit('NEXTSTEP')
    },
    previousStep({ commit }) {
        commit('PREVIOUSSTEP')
    },
    submitForm({ commit }) {
        commit('SETCOMPLETED', true)
    }
}

const getters = {
    getUser: state => state.user,
    getLoadingUser: state => state.loadingUser,
    currentStep: state => state.currentStep,
    getPersonalData: state => state.personalData,
    getProfessionalData: state => state.professionalData,
    isCompleted: state => state.completed
}

export default {
    state,
    getters,
    mutations,
    actions
};