import EspecialistaService from '../../services/EspecialistaService'
import LocalidadeService from '../../services/LocalidadeService'

const state = () => ({
    especialistas: [],
    loadingEspecialista: false,
    loadingMoreEspecialista: false,
    cidadePesquisada: '',
    nearbyCitiesComEspecialistas: [],
    total:  0,
    page: 1,
    dadosBusca: JSON.parse(localStorage.getItem('dadosBusca')) ? {estado: JSON.parse(localStorage.getItem('dadosBusca')).estado, cidade: ""} : { estado: "", cidade: ""}
})

const mutations = {
  SET_DADOS_BUSCA: (state, dadosBusca) => {
    localStorage.setItem('dadosBusca', JSON.stringify(dadosBusca));
    state.dadosBusca = dadosBusca;
  },
  SET_ESPECIALISTAS: (state, especialistas) => {
    state.especialistas = especialistas;
  },
  SET_MORE_ESPECIALISTAS: (state, especialistas) => {
    state.especialistas = [...state.especialistas, ...especialistas]
  },
  SET_TOTAL: (state, payload) => {
    state.total = payload
  },
  SET_LOADING_ESPECIALISTAS: (state, payload) => {
    state.loadingEspecialista = payload
  },
  SET_LOADING_MORE_ESPECIALISTAS: (state, payload) => {
    state.loadingMoreEspecialista = payload
  },
  SET_CITIES_ENCONTRADAS(state, cities) {
    state.nearbyCitiesComEspecialistas = cities;
  },
  CLEAR_CIDADE(state) {
    state.dadosBusca.cidade = ""
  },
  CLEAR_BUSCA(state) {
    state.especialistas = []
    state.nearbyCities = []
    state.nearbyCitiesComEspecialistas = []
    state.page = 1;
    state.total = 0;
    state.cidadePesquisada = '';
    state.radius = 10;
    state.cidades_com_especialistas = [];
  },
  ADD_PAGE(state) {
    state.page += 1
  },
  SET_CIDADE_PESQUISADA(state, city) {
    state.cidadePesquisada = city
  }
}
  
const actions = {
  async carregarEspecialista({ commit }, {city, uf, page}) {
    try {
      commit('SET_LOADING_ESPECIALISTAS', true);
      const response = await EspecialistaService.getBuscarEspecialistas(uf, city, page);
      commit('SET_ESPECIALISTAS', response.data.members.data)
      commit('SET_TOTAL', response.data.members.last_page)
      commit('SET_LOADING_ESPECIALISTAS', false);

    } catch (error) {
      commit('SET_LOADING_ESPECIALISTAS', false);
      console.error('Erro na geocodificação:', error);
    }
  },
  async carregarMoreEspecialista({ commit }, {city, uf, page}) {
    try {
      commit('SET_LOADING_MORE_ESPECIALISTAS', true);
      const response = await EspecialistaService.getBuscarEspecialistas(uf, city, page);
      setTimeout(() => {
        commit('SET_MORE_ESPECIALISTAS', response.data.members.data)
        commit('SET_TOTAL', response.data.members.last_page)
        commit('SET_LOADING_MORE_ESPECIALISTAS', false);
      }, 2000);

    } catch (error) {
      commit('SET_LOADING_MORE_ESPECIALISTAS', false);
      console.error('Erro na geocodificação:', error);
    }
  },
  async geocodeEndereco({ commit }, enderecoEspecialista) {
    try {
      commit('SET_LOADING_ESPECIALISTAS', true);
      const response = await LocalidadeService.getGeocoding(enderecoEspecialista);
      const { results } = response.data;
      if (results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        commit('SET_LOADING_ESPECIALISTAS', false);
        return ({lat, lng})
      }
    } catch (error) {
      commit('SET_LOADING_ESPECIALISTAS', false);
      console.error('Erro na geocodificação:', error);
    }
  },
  async encontrarNaRegiao({commit, state, dispatch, rootState}, { cidade, estado }) {
    commit('LOADING_CITIES_EXPERTS', true);

    dispatch('searchCities', {cidade, estado: estado.name}).then(() => {
      dispatch('carregarCidades', estado.id).then(() => {
       
        const cidadesEncontradas = [];
        const cidades_com_especialistas = rootState.localidade.cidades_com_especialistas;
        let cidades_proximas = rootState.localidade.nearbyCities;
        
        cidades_proximas.map(async (city) => {
          const cidadeEncontrada = cidades_com_especialistas.find((cidade) => cidade.name === city);
          if (cidadeEncontrada) {
            cidadesEncontradas.push(city);
          }
        });

        if(cidadesEncontradas.length == 0 || state.nearbyCitiesComEspecialistas.length == cidadesEncontradas.length){
          commit('LOADING_CITIES_EXPERTS', false);

          if(rootState.localidade.limit_NearbyCities >= 100){
            commit('SET_ALERT', {
              heading: 'erro',
              message: 'Limite de cidades ao redor atingido'
            });
          } else {
            dispatch('aumentar_NearbyCities', { cidade, estado })
          }

        } else {
          commit('LOADING_CITIES_EXPERTS', false);

          commit('SET_CITIES_ENCONTRADAS', cidadesEncontradas);
        }
      })      
    })
  }
}

const getters = {
  getCidadePesquisada: state => state.cidadePesquisada,
  getEspecialistas: state => state.especialistas,
  getNearbyCitiesComEspecialistas: state => state.nearbyCitiesComEspecialistas,
  getLoadingEspecialista: state => state.loadingEspecialista,
  getLoadingMoreEspecialista: state => state.loadingMoreEspecialista,
  getTotalPaginasEspecialistas: state => state.total,
  getDadosBusca: state => state.dadosBusca,
  getPage: state => state.page
}

export default {
  state,
  getters,
  mutations,
  actions
};