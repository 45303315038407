import LocalidadeService from '../../services/LocalidadeService'

const state = () => ({
  estados: JSON.parse(localStorage.getItem('estados')) || [],
  cidades: JSON.parse(localStorage.getItem('municipios')) || [],
  cidades_com_especialistas: JSON.parse(localStorage.getItem('cidades_com_especialistas')) || [],
  limit_NearbyCities: 5,
  nearbyCities: [],
  loadingCitiesWithExperts: false
})

const mutations = {
    SET_ESTADOS: (state, estados) => {
      localStorage.setItem('estados', JSON.stringify(estados));
      state.estados = estados;
    },
    SET_CIDADES: (state, cidades) => {
      localStorage.setItem('municipios', JSON.stringify(cidades));
      state.cidades = cidades;
    },
    SET_CIDADES_COM_ESPECIALISTAS: (state, cidades) => {
      localStorage.setItem('cidades_com_especialistas', JSON.stringify(cidades));
      state.cidades_com_especialistas = cidades;
    },
    SET_LIMIT_NEARBYCITIES: (state, radius) => {
      state.limit_NearbyCities = radius;
    },
    SET_CITIES_NEARBY(state, cities) {
      state.nearbyCities = cities;
    },
    LOADING_CITIES_EXPERTS: (state, payload) => {
      state.loadingCitiesWithExperts = payload
    },
}
  
const actions = {
  async carregarEstadosIBGE({ commit }) {
    const response = await LocalidadeService.getEstadosIBGE();
    commit('SET_ESTADOS', response.data)
  },
  async carregarCidadesIBGE({ commit }, uf) {
    const response = await LocalidadeService.getCidadesIBGE(uf);
    commit('SET_CIDADES', response.data)
  },
  async carregarEstados({ commit }) {
    const response = await LocalidadeService.getEstados();
    commit('SET_ESTADOS', response.data.states.sort((a, b) => a.name.localeCompare(b.name)))
  },
  async carregarCidades({ commit }, idEstado) {
    const response = await LocalidadeService.getCidades(idEstado);
    commit('SET_CIDADES_COM_ESPECIALISTAS', response.data.cities)
  },
  async aumentar_NearbyCities({ commit, dispatch, rootState }, { cidade, estado }) {
    commit('SET_LIMIT_NEARBYCITIES', rootState.localidade.limit_NearbyCities + 5)
    await dispatch('encontrarNaRegiao', { cidade, estado })
  },
  async searchCities({ commit , dispatch, rootState }, { cidade, estado }) {
    try {
      const {lat, lng} = await dispatch('geocodeEndereco', `${cidade}, ${estado}`)

      const response = await LocalidadeService.getCidadesProximas(lat, lng, rootState.localidade.limit_NearbyCities);
      const cities = response.data;
      const nearbyCities = cities.slice(1).map(city => city.nome); // removendo a primeira cidade
      commit('SET_CITIES_NEARBY', nearbyCities);
    } catch (error) {
      console.error(error);
    }
  }
}

const getters = {
  getEstados: state => state.estados,
  getCidades: state => state.cidades,
  getRadius: state => state.radius,
  getLoadingCitiesExperts: state => state.loadingCitiesWithExperts,
}

export default {
  state,
  getters,
  mutations,
  actions
};