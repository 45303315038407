import Api from './Api'
import axios from 'axios'

export default {
    getEstadosIBGE() {
        return Api().get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
    },
    getCidadesIBGE(uf){
        return Api().get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);
    },
    getEstados(){
        return Api().get(`/states`);
    },
    getCidades(uf){
        return Api().get(`/cities/${uf}`);
    },
    getGeocoding(endereco) {
        return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(endereco)}&key=${process.env.VUE_APP_GOOGLE_KEY}`);
    },
    getCidadesProximas(lat, lng, limit) {
        return axios.get(`https://server-geolocation.unimagem-net.com.br/api/nearbyCities?lat=${lat}&lng=${lng}&limit=${limit}`);
    }
}