// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import localidade from './modules/localidade';
import especialistas from './modules/especialistas';

// Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        localidade,
        especialistas
    }
})
